//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { documentLableDelete, documentLablePage } from '@/api/modular/fileStream/documentLabelManage'
export default {
    data() {
        return {
            queryParam: {},
            tags: [],
            result: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        onSearch() {
            this.getTags()
        },
        /**
         * 批量刪除
         */
        batchDelete() {
            this.documentLableDelete(this.result.map((j) => ({ id: j })))
        },
        documentLableDelete(record) {
            documentLableDelete(record).then((res) => {
                if (res.success) {
                    this.$message.success('刪除成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error('刪除失敗') // + res.message
                }
            })
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows.map((j) => ({ ...j, unfold: false, persons: 0 }))
                    console.log(this.tags)
                } else {
                    res.message && this.$message.warning(res.message)
                }
            })
        },
    },
}
